import { PureComponent } from 'react';
import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import CollapsingPanel from 'mycs/shared/components/CollapsingPanel/CollapsingPanel';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './CountFilter.scss';

type OptionType = {
  label: string;
  localizedLabel: string;
  quantity: number;
};

type Props = {
  options: OptionType[];
  selectedLabel?: string | null;
  onSelect: (label: any) => void;
  allValue?: string;
  filterLabel?: string;
  optionsOnly?: boolean;
};

export default function CountFilter(props: Props) {
  const { locale } = useLocale();
  const device = useDevice();
  return (
    <InnerCountFilter
      {...props}
      locale={locale}
      isSmallScreen={device.hasPhoneDimensions}
    />
  );
}

type InnerProps = Props & { locale: string; isSmallScreen: boolean };

export class InnerCountFilter extends PureComponent<InnerProps> {
  static defaultProps: Partial<InnerProps> = {
    allValue: 'All',
    filterLabel: 'Filters',
    optionsOnly: false,
  };

  /**
   * Select a filter
   */
  onClickHandler(option: any) {
    if (
      !this.props.selectedLabel ||
      option.label !== this.props.selectedLabel
    ) {
      this.props.onSelect(
        option.label === this.props.allValue ? null : option.label
      );
    }
  }

  /**
   * Render a filter link

   */
  renderLink(
    option: any,
    index: number,
    selectedLabel?: string
  ): React.ReactNode {
    const classes = classNames(styles.filter, {
      [styles.filterActive]: selectedLabel === option.label,
    });

    const onClick = () => this.onClickHandler(option);

    const quantity =
      option.quantity >= 0 ? <span>({option.quantity})</span> : null;

    return (
      <Alink
        key={`${option.localizedLabel}_${index}`}
        className={classes}
        onClick={onClick}
        text={option.label}
      >
        <span className={styles.text}>
          {option.localizedLabel} {quantity}
        </span>
      </Alink>
    );
  }

  render() {
    const { options, allValue, filterLabel, optionsOnly } = this.props;
    const selectedLabel = this.props.selectedLabel || allValue;

    const allOption = {
      label: allValue,
      localizedLabel: I18nUtils.localize(this.props.locale, allValue),
      quantity: options.reduce((sum, item) => sum + item.quantity, 0),
    };

    const items = optionsOnly ? options : [allOption].concat(options);
    const links = items.map((option, idx) =>
      this.renderLink(option, idx, selectedLabel)
    );

    return (
      <div className={styles.filters}>
        {this.props.isSmallScreen ? (
          <CollapsingPanel
            iconName="general/arrow-chevron-down.svg"
            isCompactLayout
            isTransparent
            iconClassName={styles.chevron}
            className={styles.panel}
            contentClassName={styles.panelContent}
            headClassName={styles.panelHead}
            headlineClassName={styles.panelHeadline}
            headline={filterLabel}
            content={<div>{links}</div>}
          />
        ) : (
          links
        )}
      </div>
    );
  }
}

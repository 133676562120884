/* eslint-disable */

const headerImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAA4CAYAAAALrl3YAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAXEgAAFxIBZ5/SUgAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAHlJREFUeAHt0DEBAAAAwqD1T+1lC4hAYcCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDBgwYMCAAQMGDHxgV7gAAQ1kygYAAAAASUVORK5CYII=';

export default {
  _id: '',
  locUrl: {
    'de-DE': '/',
    'en-US': '/',
    'fr-CH': '/',
    'fr-FR': '/',
    'de-CH': '/',
    'de-AT': '/',
  },
  headerImages: [
    {
      heading: '_________________'.replace(/_/g, '\xa0'),
      blurb: '____________ ____________ _______ ___ ______'.replace(
        /_/g,
        '\xa0'
      ),
      imageBase64: headerImage,
      image: {
        title: '',
        description: '',
        url: '',
        width: 1920,
        height: 930,
      },
    },
  ],
  title: '',
  seoHtml: '',
  usps: [],
};

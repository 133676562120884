import classNames from 'classnames';

import Icon from 'mycs/shared/components/Icon/Icon';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import styles from './StretchBanner.scss';

type Props = {
  imageUrl?: string;
  text?: string;
  containerClass?: string;
  logo?: string;
  logoClass?: string;
};

export default function StretchBanner({
  text = '',
  logo,
  imageUrl,
  containerClass,
  logoClass,
}: Props) {
  const style = imageUrl
    ? { backgroundImage: `url(${UrlProviderService.getImageUrl(imageUrl)})` }
    : {};

  return (
    <div className={classNames(styles.container, containerClass)} style={style}>
      {text ? <h1>{text}</h1> : null}
      {logo ? (
        <Icon
          iconContainerClass={styles.logoContainer}
          className={classNames(styles.logo, logoClass)}
          iconContent={logo}
        />
      ) : null}
    </div>
  );
}

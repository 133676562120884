import { useDesigns } from 'mycs/hooks/useDesigns';
import { useDevice } from 'mycs/router/DeviceContext';

import DesignsCarousel from 'mycs/shared/components/DesignsCarousel/DesignsCarousel';
import { CfDesignsCarousel } from '@mycs/contentful';
import SavedDesignsCarousel from 'mycs/shared/components/SavedDesignsCarousel/SavedDesignsCarousel';
import classNames from 'classnames';
import styles from './TopDesignsCarousel.scss';
interface LegacyProps {
  uuids?: string[];
  slidesToShow?: number;
  title?: string;
  isPdpLink?: boolean;
}

interface Props {
  content: CfDesignsCarousel;
  isPdpLink?: boolean;
}

export default function TopDesignsCarousel({
  content: { uuids, slidesToShow, showSavedDesigns },
  isPdpLink,
}: Props) {
  const designs = useDesigns(uuids || []);

  const { hasPhoneDimensions, hasTabletDimensions } = useDevice();

  const numberOfSlides = hasPhoneDimensions
    ? 1.35
    : hasTabletDimensions
    ? 3
    : slidesToShow || 3;

  if (showSavedDesigns) {
    return (
      <div className={classNames('contentContainer', styles.savedDesigns)}>
        <SavedDesignsCarousel topUuids={uuids || []} />
      </div>
    );
  }

  return (
    <DesignsCarousel
      key={designs[0]?.uuid}
      designs={designs}
      slidesToShow={numberOfSlides}
      isPdpLink={isPdpLink}
    />
  );
}

export function LegacyTopDesignsCarousel(props: LegacyProps) {
  return (
    <TopDesignsCarousel
      content={{
        _id: 'TopDesignsCarousel',
        _contentType: 'products',
        slidesToShow: props.slidesToShow,
        uuids: props.uuids,
        title: props.title,
      }}
      isPdpLink={props.isPdpLink ?? false}
    />
  );
}

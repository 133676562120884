import styles from './GridSplitBanner.scss';

import { CfButtonLink, CfResponsiveImage } from '@mycs/contentful';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import Alink from 'mycs/shared/components/Alink/Alink';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import classNames from 'classnames';

interface Props {
  imageData?: CfResponsiveImage;
  title?: string;
  cta?: CfButtonLink;
  reverseImage: boolean;
}

export default function GridSplitBanner({
  imageData,
  title,
  cta,
  reverseImage,
}: Props) {
  if (!imageData) {
    return null;
  }

  const { image, imagePhone } = imageData;

  return (
    <div
      className={classNames(styles.container, {
        [styles.reverseImage]: reverseImage,
      })}
    >
      <div className={styles.imageContainer}>
        <SmartImage
          src={image.url}
          className={classNames(styles.image, styles.desktopImage)}
          sizes="50vw"
        />
        <SmartImage
          src={imagePhone?.url ?? image.url}
          className={classNames(styles.image, styles.mobileImage)}
          sizes="50vw"
        />
      </div>
      <div className={styles.contentContainer}>
        <SafeText className={styles.title} content={title} />

        {cta && (
          <Alink
            href={cta.url}
            className={styles.link}
            rel="noopener"
            target="_blank"
          >
            {cta.text}
          </Alink>
        )}
      </div>
    </div>
  );
}

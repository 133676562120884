import { Fragment } from 'react';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Modal from 'mycs/shared/components/Modal/Modal';
import NewsletterForm from 'mycs/shared/components/NewsletterForm/NewsletterForm';
import SafeText from 'mycs/shared/components/SafeText/SafeText';

import styles from './NewsletterFormModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  headerImageSrc?: string;
  data: Data;
}

interface Data {
  title: string;
  text: string;
  mailingListId?: string;
}

export default function NewsletterFormModal({
  isOpen,
  onClose,
  headerImageSrc,
  data,
}: Props) {
  const { locale } = useLocale();
  const buttonProps = {
    isPrimaryCta: true,
    isFullWidth: false,
    clickOutsideWhiteList: [],
  };

  if (!data) return <Fragment />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      headerClass={styles.modalHeader}
      contentClass={styles.modalContent}
      headerImage={headerImageSrc}
    >
      <SafeText className={styles.title} content={data.title} />
      <p className={styles.text}>{data.text}</p>
      <NewsletterForm
        label={I18nUtils.localize(locale, 'E-mail')}
        labelClassName={styles.label}
        buttonProps={buttonProps}
        buttonClassName={styles.button}
        onSubmit={onClose}
        mailingListId={data.mailingListId}
      />
    </Modal>
  );
}

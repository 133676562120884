import { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { CfSellingPoints } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Anchor from 'mycs/shared/components/Anchor/Anchor';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import placeholderData from './placeholder-data';

import styles from './SellingPoints.scss';

type Props = {
  content: CfSellingPoints;
};

export default function SellingPoints({ content }: Props) {
  const { locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();
  const isFirstTimeVisitor = useMemo(
    () => !Boolean(LocalStorageUtils.get('selling-points_views')),
    []
  );

  useEffect(() => {
    /**
     * descriptions should be only showed for first time visitors, unlesss
     * noCollapse props is enabled
     * Since the same local storage key is being used for all instances,
     * this feature will only work for the first page being visited, since
     * the user won't be considered a first time visitor for other pages
     * even if it is the first time the user navigates to that page
     */
    if (isFirstTimeVisitor) {
      LocalStorageUtils.set('selling-points_views', 1);
    }
  }, []);

  const {
    big = false,
    underlined = false,
    isFullWidth,
    isTransparentBg = false,
    hideOnMobile,
    noCollapse,
    points,
    withText = true,
  } = content;

  const classes = classNames(styles.wrapper, {
    //FIXME: could this usage be replaced with CSS only media queries?
    contentContainer: !isFullWidth && !hasPhoneDimensions,
    [styles.big]: big,
    [styles.underlined]: underlined,
    [styles.fullWidth]: isFullWidth,
    [styles.transparentBg]: isTransparentBg,
    [styles.hideOnMobile]: hideOnMobile,
  });

  /**
   * Render the selling point title, wrapped by a link if needed
   */
  const renderTitle = (point: any) => {
    const icon =
      point.icon || point.iconName ? (
        <div className={styles.icon}>
          {point.icon ? (
            <Icon iconPath={point.icon.url} />
          ) : (
            <Icon iconName={point.iconName} />
          )}
        </div>
      ) : null;

    const titleClass = classNames(styles.title, {
      [styles.singleTitle]: points?.length === 1,
    });

    const contents = (
      <div className={styles.titleContainer}>
        {icon}
        <h5 className={titleClass}>{I18nUtils.localize(locale, point.text)}</h5>
      </div>
    );

    return point.link ? (
      <Anchor destination={`#${point.link}`} text={contents} />
    ) : (
      contents
    );
  };

  const showDescription = isFirstTimeVisitor || noCollapse;

  const pointsOrDefault =
    points && points.length ? points : placeholderData.points;

  const pointClassname = classNames(styles.point, {
    [styles.withText]: showDescription,
    //@ts-ignore
    [styles.placeholder]: /^[\xa0\s]+$/.test(pointsOrDefault[0].text),
  });

  return (
    <div className={classes}>
      <div className={styles.container}>
        <div
          className={styles.row}
          style={
            points && points.length > 3
              ? { justifyContent: 'space-between' }
              : undefined
          }
        >
          {pointsOrDefault.map((point, i) => (
            <div
              className={pointClassname}
              key={i}
              id={`usp-${point._id}`}
              data-testid="selling-point"
            >
              {renderTitle(point)}

              {point.longText && showDescription && withText && (
                <SafeText
                  className={styles.text}
                  content={I18nUtils.localize(locale, point.longText)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function LegacySellingPoints(
  props: Omit<CfSellingPoints, '_id' | '_contentType' | 'title'>
) {
  return (
    <SellingPoints
      content={{
        ...props,
        _id: 'legacySellingPoints',
        _contentType: 'responsiveVideo',
        title: 'Legacy Selling points',
      }}
    />
  );
}

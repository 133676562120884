import { useEffect, useMemo } from 'react';

import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { useAppDispatch, useAppSelector } from 'mycs/shared/state/store';
import {
  getDesignsFromReduxState,
  setDesignsData,
} from 'mycs/shared/state/slices/designsSlice';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import Logger from 'mycs/shared/services/Logger';
import { useLocation } from 'react-router-dom';

export function useDesigns(
  uuids: string[],
  withShippingDetails = false
): Design[] {
  const { countryCode, locale } = useLocale();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const serializedUuids = JSON.stringify(uuids);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uniqueUuids = useMemo(() => [...new Set(uuids)], [serializedUuids]);
  const designs = useAppSelector((state) =>
    getDesignsFromReduxState(state, uuids)
  );
  // Preserve the original uuid order.
  const orderedDesigns = useMemo(
    () =>
      uuids
        .map((uuid) => designs.find((design) => design.uuid === uuid))
        .filter((design): design is Design => !!design),
    [uuids, designs]
  );

  useEffect(() => {
    if (!uniqueUuids || uniqueUuids.length === 0) {
      return;
    }

    DesignApiService.getDesignsByUuids(
      uniqueUuids,
      countryCode,
      withShippingDetails
    )
      .then((designs: Design[]) => {
        dispatch(setDesignsData({ locale, pathname, designs }));
      })
      .catch((err) => {
        err.message = `useDesigns: Couldn't load designs: ${err}`;
        Logger.error(err);
      });
  }, [
    countryCode,
    dispatch,
    locale,
    pathname,
    uniqueUuids,
    withShippingDetails,
  ]);

  return orderedDesigns;
}

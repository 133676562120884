import { Fragment, useState } from 'react';
import classNames from 'classnames';

import type { CfGridBanner } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import MediaPreview from 'mycs/shared/components/MediaPreview/MediaPreview';
import NewsletterFormModal from 'mycs/shared/components/NewsletterFormModal/NewsletterFormModal';
import ProductPreview from 'mycs/shared/components/ProductPreview/ProductPreview';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';

import styles from './GridBanner.scss';

interface NewsletterFormModalData {
  title: string;
  text: string;
  mailingListId?: string;
}

interface FeaturedProduct {
  uuid: string;
  hideImagesCarousel?: boolean;
}

interface Props {
  data: CfGridBanner;
  bannerDesigns: Design[];
  imageSizes?: string;
  previewProps: {
    isPdpLink?: boolean;
  };
}

export default function GridBanner({
  data,
  bannerDesigns,
  imageSizes,
  previewProps,
}: Props) {
  const { hasPhoneDimensions } = useDevice();

  return (
    <div
      className={classNames(styles.container, {
        // FIXME: could be done through CSS
        [styles.mobileLayout]: hasPhoneDimensions,
      })}
    >
      {data.type === 'Featured product' && (
        <ProductGridBanner
          data={data}
          bannerDesigns={bannerDesigns}
          imageSizes={imageSizes}
          previewProps={previewProps}
        />
      )}

      {data.type === 'Newsletter form' && (
        <SubscriptionGridBanner data={data} imageSizes={imageSizes} />
      )}
    </div>
  );
}

function ProductGridBanner({
  data,
  bannerDesigns,
  imageSizes,
  previewProps,
}: {
  data: CfGridBanner;
  bannerDesigns: Design[];
  imageSizes?: string;
  previewProps: {
    isPdpLink?: boolean;
  };
}) {
  const { hasPhoneDimensions } = useDevice();
  const { hideImagesCarousel } = data.gridBannerSubtype[0] as FeaturedProduct;
  const { isPdpLink } = previewProps;

  const product = bannerDesigns.find((design) => {
    if (!data.gridBannerSubtype[0] || !('uuid' in data.gridBannerSubtype[0])) {
      return false;
    }

    return design.uuid === data.gridBannerSubtype[0].uuid;
  });

  if (!product) {
    return null;
  }

  return (
    <ProductPreview
      product={product}
      banner={data}
      isPdpLink={hasPhoneDimensions || isPdpLink}
      imageSizes={imageSizes}
      showImagesCarousel={!hideImagesCarousel}
    />
  );
}

function SubscriptionGridBanner({
  data,
  imageSizes,
}: {
  data: CfGridBanner;
  imageSizes?: string;
}) {
  const { hasPhoneDimensions } = useDevice();
  const [showModal, setShowModal] = useState(false);
  const { locale } = useLocale();

  /**
   * Track the inspiration clicks
   */
  const onClick = () => {
    setTimeout(() => {
      setShowModal(true);
    }, 200); // Timeout is to prevent this click not to close the form as an outside click
  };

  /**
   * On modal close
   */
  const onClose = () => {
    setShowModal(false);
  };

  const badges = [];
  badges.push(
    <div className={styles.badge} key="badge">
      <span>{I18nUtils.localize(locale, 'Coming soon')}</span>
    </div>
  );
  const title = <span className={styles.title}>{data.customTitle}</span>;

  const subtitle = (
    <span className={styles.subtitle}>{data.customSubtitle}</span>
  );

  const newsletterFormModalData = data
    .gridBannerSubtype[0] as NewsletterFormModalData;
  if (!newsletterFormModalData) return <Fragment />;

  const image = ContentfulUtils.imageSelect(data.customImage);

  return (
    <>
      <MediaPreview
        title={title}
        isBigTile
        isGridBanner
        subtitle={subtitle}
        imageSrc={image?.url}
        imageAlt={image?.description}
        imageTitle={image?.title}
        imageWidth={image?.width}
        imageHeight={image?.height}
        button={
          <div className={styles.ctaButton}>
            <Button
              isExpandingIconCta
              text={'Be the first to know!'}
              iconName="general/envelope"
              className={styles.expandingButton}
            />
          </div>
        }
        onClick={onClick}
        badges={badges}
        imageSizes={imageSizes}
        isMobileVersion={hasPhoneDimensions}
      />
      <NewsletterFormModal
        isOpen={showModal}
        onClose={onClose}
        headerImageSrc={image?.url}
        data={newsletterFormModalData}
      />
    </>
  );
}

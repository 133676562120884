import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import { useMaterialConfig } from 'mycs/hooks/useMaterialConfig';
import Alert from 'mycs/shared/components/Alert/Alert';

import styles from './MaterialEOLWarning.scss';

export function MaterialEOLWarning({ designs }: { designs: any[] }) {
  const { hasPhoneDimensions, hasTabletDimensions, isTablet } = useDevice();

  const { getHighesPrioConfigFromDesigns } = useMaterialConfig();

  let materialEOLConfig = getHighesPrioConfigFromDesigns(designs, true);

  if (!materialEOLConfig || !materialEOLConfig.infoMessage) {
    return null;
  }

  const { text, button } = materialEOLConfig.infoMessage;

  /**
   * Render Info message banner for products with limited colours
   */
  return (
    <div
      className={classNames('contentContainer', styles.infoMessageContainer)}
    >
      <Alert
        text={text}
        button={button}
        isInfoMessage
        isLeftAligned={isTablet || hasTabletDimensions || hasPhoneDimensions} // FIXME: could be done through CSS
        hideForeverId={`${text}-info-message-dismissed-count`}
      />
    </div>
  );
}

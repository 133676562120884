import { useLocation } from 'react-router-dom';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export function useUrlPagination(pageParameter = 'page') {
  const { locale } = useLocale();
  const localizedPageParameter = I18nUtils.localize(locale, pageParameter);
  const { search, ...location } = useLocation();
  const activePage = parseInt(
    new URLSearchParams(search).get(localizedPageParameter) || '1',
    10
  );

  return {
    activePage,
    getPageLocation(page: number) {
      const searchParams = new URLSearchParams(search);

      if (page > 1) searchParams.set(localizedPageParameter, page.toString());
      else {
        searchParams.delete(localizedPageParameter);
      }

      return {
        ...location,
        search: searchParams.toString(),
      };
    },
    getPageRel(page: number): string | undefined {
      if (page === activePage - 1) {
        return 'prev';
      }

      if (page === activePage + 1) {
        return 'next';
      }

      return undefined;
    },
  };
}

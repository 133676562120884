import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useUrlPagination } from 'mycs/hooks/useUrlPagination';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useTrackEvent } from '../TrackEvent/useTrackEvent';

import styles from './Pagination.scss';
import buttonStyles from '../Button/Button.scss';

type PaginationButtonsProps = {
  activePage: number;
  onPageChange: (index: number) => void;
  totalPages: number;
  isLoadMore?: boolean;
  getPageLocation: (page: number) => any;
  getPageRel: (page: number) => string | undefined;
};

function PaginationButton({
  page,
  isActive,
  onClick,
  getPageLocation,
  getPageRel,
}: {
  page: number;
  isActive: boolean;
  onClick: () => void;
  getPageLocation: (page: number) => any;
  getPageRel: (page: number) => string | undefined;
}) {
  const trackEvent = useTrackEvent({
    name: 'pagination',
    label: `page ${page.toString()}`,
  });

  const classes = classNames(buttonStyles.button, styles.pageButton, {
    [styles.activePageButton]: isActive,
  });

  if (getPageLocation(page) === undefined) {
    return (
      <div key={page} className={classes}>
        <span className={buttonStyles.button__text}>{page}</span>
      </div>
    );
  }

  return (
    <Link
      className={classes}
      onClick={trackEvent.wrapOnClick(onClick)}
      to={getPageLocation(page)}
      rel={getPageRel(page)}
    >
      <span className={buttonStyles.button__text}>{page}</span>
    </Link>
  );
}

export function PaginationButtons({
  activePage,
  onPageChange,
  totalPages,
  isLoadMore,
  getPageLocation,
  getPageRel,
}: PaginationButtonsProps) {
  const { locale } = useLocale();

  function renderPageButton(page: number) {
    const onClick = () => {
      if (activePage !== page) {
        onPageChange(page);
      }
    };

    return (
      <PaginationButton
        key={page}
        page={page}
        isActive={page === activePage}
        onClick={onClick}
        getPageLocation={getPageLocation}
        getPageRel={getPageRel}
      />
    );
  }

  function renderLoadMore(page: number) {
    const classes = classNames(
      buttonStyles.button,
      buttonStyles.fullWidth,
      buttonStyles.primaryCta,
      styles.loadMore
    );

    if (activePage >= totalPages) {
      return null;
    }

    return (
      <Link
        key={activePage}
        className={classes}
        onClick={() => onPageChange(page)}
        to={getPageLocation(page)}
        rel={getPageRel(page)}
      >
        <span className={buttonStyles.button__text}>
          {I18nUtils.localize(locale, 'Load more')}
        </span>
      </Link>
    );
  }

  if (isLoadMore) {
    return renderLoadMore(activePage + 1);
  }

  if (totalPages <= 1) return null;

  const pages = [];
  for (let page = 1; page <= totalPages; page++) {
    pages.push(renderPageButton(page));
  }

  return <div className={styles.container}>{pages}</div>;
}

type Props = {
  totalPages: number;
  onPageChange?: (index: number) => void;
  isLoadMore?: boolean;
};

export default function Pagination({
  onPageChange = () => null,
  totalPages,
  isLoadMore,
}: Props) {
  const { activePage, getPageLocation, getPageRel } = useUrlPagination();

  return (
    <PaginationButtons
      activePage={activePage}
      onPageChange={onPageChange}
      totalPages={totalPages}
      isLoadMore={isLoadMore}
      getPageLocation={getPageLocation}
      getPageRel={getPageRel}
    />
  );
}

export default {
  points: [
    {
      _id: '2ulR3d0s9qeA8MoG0u0mUU',
      slug: '',
      icon: {
        url: '/img/icons/usp-ribbon/placeholder.svg',
      },
      text: '__________ _________'.replace(/_/g, '\xa0'),
      longText:
        '__ ________ ____ ____________ _____ ____ _____ ___ ________ ____ ___ _____ _______ ____ _____________ ___________'.replace(
          /_/g,
          '\xa0'
        ),
    },
    {
      _id: 'KfAagIov6gCmk24Eoqa42',
      slug: '',
      icon: {
        url: '/img/icons/usp-ribbon/placeholder.svg',
      },
      text: '__________ _________'.replace(/_/g, '\xa0'),
      longText:
        '__ ________ ____ ____________ _____ ____ _____ ___ ________ ____ ___ _____ _______ ____ _____________ ___________'.replace(
          /_/g,
          '\xa0'
        ),
    },
    {
      _id: '6nf1rPpZ3a8EIgkqImUSw4',
      slug: '',
      icon: {
        url: '/img/icons/usp-ribbon/placeholder.svg',
      },
      text: '__________ _________'.replace(/_/g, '\xa0'),
      longText:
        '__ ________ ____ ____________ _____ ____ _____ ___ ________ ____ ___ _____ _______ ____ _____________ ___________'.replace(
          /_/g,
          '\xa0'
        ),
    },
  ],
};

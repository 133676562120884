import { useNavigate, useLocation } from 'react-router-dom';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export function useUrlFilter(filterName: string) {
  const { locale } = useLocale();
  const localizedFilterName = I18nUtils.localize(locale, filterName);
  const { search, ...location } = useLocation();
  const navigate = useNavigate();
  const selectedFilter = new URLSearchParams(search).get(localizedFilterName);

  return {
    selectedFilter,
    updateFilter(filter: string | null) {
      const searchParams = new URLSearchParams(search);

      if (filter) {
        searchParams.set(localizedFilterName, filter);
      } else {
        searchParams.delete(localizedFilterName);
      }

      navigate({
        ...location,
        search: searchParams.toString(),
      });
    },
  };
}

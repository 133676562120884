import countBy from 'lodash/countBy';
import uniqBy from 'lodash/uniqBy';
import sum from 'lodash/sum';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useUrlFilter } from 'mycs/hooks/useUrlFilter';
import CountFilter from 'mycs/shared/components/CountFilter/CountFilter';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export function useFurnitureTypeFilter({
  designs = [],
  getFurnitureLabel,
}: {
  designs: any[];
  getFurnitureLabel?: (label: string | null) => string | undefined;
}) {
  const { locale } = useLocale();
  const { selectedFilter, updateFilter } = useUrlFilter('Filter');
  const counts = countBy(designs, 'furniture_type');
  const options = getOptions(counts);
  const selectedOptions = getSelectedFilterOption(options);
  let filteredDesigns = designs.filter((item) =>
    selectedOptions
      ? selectedOptions.some(
          (option) => option.furnitureType === item.furniture_type
        )
      : Boolean(item.furniture_type)
  );

  if (!filteredDesigns.length) filteredDesigns = designs;

  return {
    filteredDesigns,
    FurnitureTypeFilter,
    selectedFilter,
    updateFilter,
    options,
  };

  /**
   * Find the previously selected value in the new options
   */
  function getSelectedFilterOption(options: any[]): any[] | null {
    if (!selectedFilter) return null;

    const localizedLabel = I18nUtils.localize(
      locale,
      getFurnitureLabel?.(selectedFilter)
    );

    return options.filter((option) => option.localizedLabel === localizedLabel);
  }

  function getOptions(counts: any): any[] {
    return Object.keys(counts)
      .filter(Boolean)
      .map((furnitureType) => ({
        furnitureType,
        label: furnitureType,
        localizedLabel: getFurnitureLabel
          ? I18nUtils.localize(locale, getFurnitureLabel(furnitureType))
          : '',
        quantity: counts[furnitureType],
      }));
  }

  /**
   * Render the design filter
   */
  function FurnitureTypeFilter() {
    // Merge options based on the localized label
    const filterOptions = getFilterOptions(options);

    if (filterOptions.length <= 1) return null;

    return (
      <CountFilter
        options={filterOptions}
        onSelect={updateFilter}
        selectedLabel={selectedFilter}
      />
    );

    /**
     * Return unique options to be shown
     */
    function getFilterOptions(options: any[]): any[] {
      return uniqBy(options, (option) => option.localizedLabel).map(
        ({ label, localizedLabel, furnitureType }) => {
          const similarOptions = options.filter(
            (option) => option.localizedLabel === localizedLabel
          );
          return {
            label,
            localizedLabel,
            furnitureType,
            quantity: sum(similarOptions.map((option) => option.quantity)),
          };
        }
      );
    }
  }
}

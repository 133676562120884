import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';

import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import DesignsCarousel from 'mycs/shared/components/DesignsCarousel/DesignsCarousel';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './SavedDesignsCarousel.scss';
import { useUser } from 'mycs/hooks/useUser';
import { useDesigns } from 'mycs/hooks/useDesigns';

type Props = {
  topUuids: string[];
};

export default function SavedDesignsCarousel({ topUuids }: Props) {
  const { locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();
  const { user } = useUser();

  const designUUIDs = useMemo(() => {
    const uuids = user?.designs.map((design) => design.uuid) || [];

    return [...uuids, ...topUuids];
  }, [user, topUuids]);
  const designs = useDesigns(designUUIDs);

  if (
    !designs.length ||
    (!hasPhoneDimensions && designs.length < 3) ||
    (hasPhoneDimensions && designs.length < 2)
  )
    return null;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {I18nUtils.localize(locale, 'Your current saved designs')}
      </h3>

      <DesignsCarousel
        designs={uniqBy(designs, 'uuid')}
        showProductMeasurements
      />
    </div>
  );
}

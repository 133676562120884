import classNames from 'classnames';

import { CfHeroBlock } from '@mycs/contentful';
import Button from 'mycs/shared/components/Button/Button';
import CfImage from 'mycs/shared/components/CfImage/CfImage';
import ContentfulUtils from 'mycs/shared/utilities/ContentfulUtils/ContentfulUtils';

import styles from './HeroBlock.scss';

type CustomCfHeroBlock = CfHeroBlock & {
  columnized?: boolean;
  className?: string;
};

export default function HeroBlock({ content }: { content: CustomCfHeroBlock }) {
  const img = ContentfulUtils.getImage({
    image: content.image,
    imageTablet: content.imageTablet,
    imagePhone: content.imagePhone,
  });
  const colourMode =
    content.textColour === 'Dark' ? styles.darkMode : styles.lightMode;
  const position =
    content.position === 'Top'
      ? styles.heroBlockTop
      : content.position === 'Bottom'
      ? styles.heroBlockBottom
      : '';

  const classes = classNames(
    'contentContainer',
    styles.container,
    colourMode,
    { [styles.columnizedBlocks]: content.columnized },
    position,
    content.className
  );

  return (
    <div className={classes}>
      <div className={styles.imageWrapper}>
        {img && <CfImage className={styles.fitImage} asset={img} />}
      </div>

      <div className={classNames(styles.contentContainer)}>
        {content.title && <div className={styles.title}>{content.title}</div>}

        {content.text && <div className={styles.text}>{content.text}</div>}

        {content.buttonText && content.buttonLink && (
          <div className={styles.button}>
            <Button
              href={content.buttonLink}
              text={content.buttonText}
              isPrimaryCta
            />
          </div>
        )}
      </div>
    </div>
  );
}
